import React from "react";
import Layout from "./layout";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ChevronRightIcon } from "@heroicons/react/solid";
import SEO from "../seo/SEO";


const PressRelease = ({ data }) => {
  return (
    <>
<SEO />
      <Layout>
        <main>
          <section className="sm:max-w-screen-sm mx-8 sm:mx-16 md:mx-auto mt-20 mb-10 md:my-20 flex flex-col md:space-y-2">
            <h3 className="text-lg md:text-xl font-medium uppercase text-blue-500">
              {data.prismicPressRelease.data.tag}
            </h3>
            <h1 className="text-3xl md:text-[42px] font-medium leading-relaxed text-gray-300">
              {data.prismicPressRelease.data.release_title.text}
            </h1>
          </section>
          <section className="bg-gray-800 lg:max-w-screen-lg mx-auto mb-16 rounded-none lg:rounded-2xl sm:hidden !pb-0">
            <div className="aspect-w-5 aspect-h-9 !pb-0">
              <GatsbyImage
                image={getImage(data.prismicPressRelease.data.cover_image)}
                className="object-cover rounded-none lg:rounded-2xl"
              />
            </div>
          </section>
          <section className="bg-gray-800 lg:max-w-screen-lg mx-auto mb-16 rounded-none lg:rounded-2xl hidden sm:block md:hidden !pb-0">
            <div className="sm:aspect-w-4 sm:aspect-h-3 !pb-0">
              <GatsbyImage
                image={getImage(data.prismicPressRelease.data.cover_image)}
                className="object-cover rounded-none lg:rounded-2xl"
              />
            </div>
          </section>
          <section className="bg-gray-800 lg:max-w-screen-lg mx-auto mb-16 rounded-none lg:rounded-2xl hidden md:block !pb-0">
            <div className="md:aspect-w-16 md:aspect-h-9 !pb-0">
              <GatsbyImage
                image={getImage(data.prismicPressRelease.data.cover_image)}
                className="object-cover rounded-none lg:rounded-2xl"
              />
            </div>
          </section>
          <section className="max-w-screen-md mx-8 md:mx-auto mb-16">
            <div
              className="prose mx-auto"
              dangerouslySetInnerHTML={{
                __html: data.prismicPressRelease.data.blog_article.html,
              }}
            ></div>
          </section>

          <section className="sm:max-w-screen-sm mx-8 sm:mx-16 md:mx-auto mt-20 mb-10 md:my-20 border-t border-b border-gray-700 grid grid-cols-2 gap-3 py-8">
            <ul className="flex flex-col space-y-5">
              <h3 className="text-2xl font-medium text-gray-300">
                Press Contact
              </h3>
              <ul className="flex flex-col space-y-2">
                <p className="text-lg text-gray-400 font-light">
                  {data.prismicPressRelease.data.pr_contact_name}
                </p>
                <p className="text-lg text-gray-400 font-light">
                  {data.prismicPressRelease.data.pr_contact_number}
                </p>
              </ul>
            </ul>
            <ul className="flex flex-col space-y-5 items-start">
              
            </ul>
          </section>
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query PressQuery($slug: String) {
    prismicPressRelease(data: { slug: { eq: $slug } }) {
      data {
        slug
        tag
        cover_image {
          gatsbyImageData
        }
        release_title {
          text
        }
        blog_article {
          html
        }
        pr_contact_name
        pr_contact_number
        press_kit_link {
          url
        }
      }
    }
  }
`;

export default PressRelease;
